<template>
    <div class="container">
        <SearchCliente :nombrCC="nombrCC" :clienNom="clienNom" :centrosPe="centrosPe" />
        <main class="main">
            <div class="button-container">
                <button @click="toggleSearch" class="butonSearch">Buscar clientes</button>
            </div>

            <div class="search-container" v-if="showSecondSearch">
                <div class="search-inputs">
                    <input type="text" v-model="searchQuery" ref="clienteSearchInput"  @click="selectinput()"
                        placeholder="Ingrese el nombre o nit del cliente" class="search-input" />
                </div>
                <div v-if="showClientes && filteredClientes.length" class="info-card">
                    <h3>Clientes Encontrados:</h3>
                    <div class="info-card-container">
                        <div v-for="cliente in filteredClientes" :key="cliente.id" @click="selectCliente(cliente)"
                            class="info-item-horizontal">
                            <div class="info-detail-horizontal">
                                <span class="info-title">Razón Social:</span>
                                <span class="info-value">{{ cliente.razonSocial }}</span>
                            </div>
                            <div class="info-detail-horizontal">
                                <span class="info-title">NIT:</span>
                                <span class="info-value">{{ cliente.nit }}</span>
                            </div>
                            <div class="info-detail-horizontal">
                                <span class="info-title">Código de Precio:</span>
                                <span class="info-value">{{ cliente.codPrecio }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="filteredCC.length" class="info-card" >
                    <h3>Centros de Costo Encontrados:</h3>
                    <!-- Filtro para Centros de Costo -->
                    <input type="text" v-model="searchQueryCC" placeholder="Filtrar centros de costo" 
                        class="search-inputCC" />
                    <div v-if="showCentroCosto">
                        <div class="info-card-containerCC">
                            <div v-for="centroCosto in filteredCC" :key="centroCosto.CodigoCC"
                                @click="selectCC(centroCosto)" class="info-item-horizontal">
                                <div class="info-detail-horizontal">
                                    <span class="info-title">Centro de Costo:</span>
                                    <span class="info-value">{{ centroCosto.CodigoCC }}</span>
                                </div>
                                <div class="info-detail-horizontal">
                                    <span class="info-title">Nombre:</span>
                                    <span class="info-value">{{ centroCosto.Nombre }}</span>
                                </div>
                                <div class="info-detail-horizontal">
                                    <span class="info-title">Código de Precio:</span>
                                    <span class="info-value">{{ centroCosto.codPrecio }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-else>No se encontraron centros de costo.</p>

                <div v-if="puntoEnvio.length" class="info-card">
                    <h3>Puntos de Envío Encontrados:</h3>
                    <input type="text" v-model="filterPuntoEnvio" placeholder="Filtrar puntos de envio"
                        class="search-inputPE" />
                    <div v-if="showPuntoEnvio">
                        <div class="info-card-containerPE">
                            <div v-for="centroPe in puntoEnvio" :key="centroPe.codigoPE" @click="selectPE(centroPe)"
                                class="info-item-horizontal">
                                <div class="info-detail-horizontal">
                                    <span class="info-title">Punto de Envío:</span>
                                    <span class="info-value">{{ centroPe.codigoPE + ' - ' + centroPe.Nombre }}</span>
                                </div>
                                <div class="info-detail-horizontal">
                                    <span class="info-title">Ciudad:</span>
                                    <span class="info-value">{{ centroPe.Ciudad }}</span>
                                </div>
                                <div class="info-detail-horizontal">
                                    <span class="info-title">Dirección de Entrega:</span>
                                    <span class="info-value">{{ centroPe.DireccionEntrega }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-else>No se encontraron puntos de envío.</p>
            </div>
        </main>

    </div>
</template>


<script>
import SearchCliente from "../components/SearchCliente.vue";
import Cookies from 'js-cookie';
import { ref } from 'vue';

import {
    searchPClientes as apiClientes,
    searchCC as searchC,
    selectP as selectPe,
} from "@/router/router";

export default {

    components: {
        SearchCliente,

    },

    setup() {
        // Estado reactivo
        let userPWAData = ref(Cookies.get('userPWA') ? JSON.parse(Cookies.get('userPWA')) : null);
        const searchQuery = ref('');
        const showSecondSearch = ref(false);
        const clientes = ref([]);
        const centroCostos = ref([]);
        const clienNit = ref('');
        const clienNom = ref('');
        const searchQueryCC = ref('');
        const nombrCC = ref('');
        const puntoEnvio = ref([]);
        const centrosPe = ref('');
        const showClientes = ref(true);
        const showCentroCosto = ref(true);
        const showPuntoEnvio = ref(true);

        // Función para borrar cookies
        const borrarCook = () => {

            // Lee la cookie 'userPWA
            if (userPWAData.value) {

                const userPWA = userPWAData.value;

                // Elimina campos específicos del objeto
                delete userPWA.razonSocial;
                delete userPWA.nombreCC;
                delete userPWA.nombrePE;
                delete userPWA.codigoCC;
                delete userPWA.codigoPE;
                userPWA.nit = userPWA.nitLogueado;

                // Vuelve a establecer la cookie con el objeto modificado
                Cookies.set('userPWA', JSON.stringify(userPWA));

            }
            //console.log(userPWAData)
        };

        // Ejecutar borrarCook cuando el componente se monte
        // onMounted(() => {
        //     borrarCook();
        // });

        return {
            userPWAData,
            searchQuery,
            showSecondSearch,
            clientes,
            centroCostos,
            clienNit,
            clienNom,
            searchQueryCC,
            nombrCC,
            puntoEnvio,
            centrosPe,
            showClientes,
            showCentroCosto,
            showPuntoEnvio,
            borrarCook
        };
    },
    computed: {



        filteredClientes() {
            const query = this.searchQuery.trim().toLowerCase();
            if (this.searchQuery == '') {
                //console.log('holassssssssssssss' );
            }
            return query
                ? this.clientes.filter((cliente) => {
                    return (
                        cliente.razonSocial?.toLowerCase().includes(query) ||
                        cliente.nit?.toLowerCase().includes(query)
                    );
                })
                : this.clientes;
        },


        filteredCC() {
            const queryCC = this.searchQueryCC.trim().toLowerCase();
            if (this.searchQueryCC == '') {
                //console.log('holassssssssssssss' );
            }
            return queryCC
                ? this.centroCostos.filter((centroCosto) => {
                    return (
                        centroCosto.CodigoCC?.toLowerCase().includes(queryCC) ||
                        centroCosto.Nombre?.toLowerCase().includes(queryCC) ||
                        centroCosto.codPrecio?.toLowerCase().includes(queryCC)
                    );
                })
                : this.centroCostos;

        },

    },

    methods: {
        
        toggleSearch() {
            this.showSecondSearch = !this.showSecondSearch;
            if (this.showSecondSearch) {
                this.showClientes = true; // Asegura que se muestre el selector cuando se activa la búsqueda
            }
        },

        async searchClientes() {
            
            try {
                const response = await apiClientes();
                this.clientes = response.data[0] || [];
            } catch (error) {
                console.error("Error al consultar el cliente:", error);
            }
        },

        async selectCliente(cliente) {
            
            try {
                this.searchQuery = cliente.razonSocial;
                const response = await searchC({ Opcion: 4, Nit: cliente.nit });

                this.centroCostos = Array.isArray(response.data) ? response.data : [];

                this.userPWAData.nit = cliente.nit;
                this.userPWAData.razonSocial = cliente.razonSocial;
                this.userPWAData.codprecio = cliente.codPrecio;
                Cookies.set('userPWA', JSON.stringify(this.userPWAData));
                this.showClientes = false;
                
            } catch (error) {
                console.error("Error al consultar la API:", error);
            }
        },


        async selectCC(centroCosto) {

            this.searchQueryCC = centroCosto.Nombre;
            this.userPWAData.codigoCC = centroCosto.CodigoCC;
            this.userPWAData.nombreCC = centroCosto.Nombre;
            Cookies.set('userPWA', JSON.stringify(this.userPWAData));

            this.filterCentroCosto = centroCosto.Nombre;

            try {
                const response = await selectPe({
                    Opcion: 2,
                    CodigoCC: centroCosto.CodigoCC,
                });
                this.puntoEnvio = Array.isArray(response.data) ? response.data : [];

                this.showCentroCosto = false;
            } catch (error) {
                console.error("Error al consultar la API:", error);
            }
        },

        selectPE(puntoEnvio) {
            this.filterPuntoEnvio = puntoEnvio.Nombre;
            this.userPWAData.codigoPE = puntoEnvio.codigoPE;
            this.userPWAData.nombrePE = puntoEnvio.Nombre;
            this.userPWAData.DireccionEntrega = puntoEnvio.DireccionEntrega;
            Cookies.set('userPWA', JSON.stringify(this.userPWAData));
            this.showPuntoEnvio = false;
        
            
            //this.filterCentroCosto = centroCosto.Nombre
            //location.reload();
        },
        
    selectinput() {
   
   this.showClientes = true;



},
    },



    created() {
        this.searchClientes();


    },
};
</script>

<style scoped>
.container {
    padding: 16px;
    height: 90vh;
}

.button-container {
    text-align: center;
    margin-top: 7px;
}

.butonSearch {
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #ffcb05;
    color: black;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: Arial, sans-serif;
}

.butonSearch:hover {
    background-color: #be9904;
}

.search-container {
    margin-top: 16px;
}


.search-input {
    margin-bottom: 12px;
    padding: 8px;
    width: 30%;
    box-sizing: border-box;
    border-radius: 5px 5px 5px 5px;
}

.search-inputCC {
    margin-bottom: 12px;
    padding: 8px;
    width: 30%;
    box-sizing: border-box;
    border-radius: 5px 5px 5px 5px;
}

.search-inputPE {
    margin-bottom: 12px;
    padding: 8px;
    width: 30%;
    box-sizing: border-box;
    border-radius: 5px 5px 5px 5px;
}

.info-card {
    margin-top: 16px;
    width: 25%;
    display: contents;
}

.info-card-container {
    max-height: 440px;
    overflow-y: auto;
}

.info-card-containerCC {
    max-height: 440px;
    overflow-y: auto;
}

.info-card-containerPE {
    max-height: 300px;
    overflow-y: auto;
}

.info-item-horizontal {

    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: #e7e7e7;
}

.info-detail-horizontal {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.info-title {
    font-weight: bold;
}


@media (max-width: 600px) {
    .search-input {
        font-size: 14px;
    }

    .info-item-horizontal {
        font-size: 14px;
        flex-direction: column;
    }

    .info-detail-horizontal {
        margin-right: 0;
        margin-bottom: 8px;
    }

    .info-title {
        margin-bottom: 4px;
    }

}

@media (max-width: 800px) {
    .search-input {

        width: 70%;
    }

    .search-inputCC {
        width: 70%;
    }

    .search-inputPE {
        width: 70%;
    }

    .container {
        height: 100%;
    }
}
</style>